import { i18n } from "../services/i18n.service";

export const AlertConfirm = (title: string, message: string, okay: Function = () => {
}, cancel: Function = () => {
}, okayText = i18n('Delete').t('de', 'Löschen').get(), cancelText = i18n('Cancel').t('de', 'Abbrechen').get(), cancelRole = 'cancel') => {
  const alert: any = document.createElement('ion-alert');
  alert.header = title;
  alert.message = message;
  alert.buttons = [
    {
      text: cancelText,
      role: cancelRole,
      cssClass: 'secondary',
      handler: (event) => {
        cancel(event)
      }
    }, {
      text: okayText,
      handler: (event) => {
        okay(event)
      }
    }
  ];
  document.body.appendChild(alert);
  return alert.present();
};

export const Alert = (title: string, message: string, okayText = 'Okay', cancelText = i18n('Delete').t('de', 'Löschen').get()) => {
  const alert: any = document.createElement('ion-alert');
  alert.header = title;
  alert.message = message;
  alert.buttons = [okayText, cancelText];
  document.body.appendChild(alert);
  return alert.present();
};
